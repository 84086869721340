@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

select:focus,
textarea:focus,
input:focus {
  outline: none;
}
select,
input,
textarea {
  color-scheme: light;
  background-color: white;
}
.mirror-Animation {
  position: relative;
  overflow: hidden;
}

.mirror-Animation::before {
  content: "";
  position: absolute;
  rotate: 50deg;
  height: 500px;
  bottom: -200px;
  background: linear-gradient(90deg, #f0f5fe00 0%, #f0f5fecc 100%);
  opacity: 0.4;
  animation: mirror-animation 3.5s linear infinite;
}

.custom-blue-pulse {
  animation: 1.5s custom-pulse-blue-animation infinite ease-in-out;
}
.custom-red-pulse {
  animation: 1.5s custom-pulse-red-animation infinite ease-in-out;
}

@keyframes custom-pulse-blue-animation {
  0% {
    box-shadow: 0 0 0 0 #2136d4;
  }

  100% {
    box-shadow: 0 0 0 15px #2136d400;
  }
}
@keyframes custom-pulse-red-animation {
  0% {
    box-shadow: 0 0 0 0 #ff0000;
  }

  100% {
    box-shadow: 0 0 0 15px #ff000000;
  }
}

@keyframes mirror-animation {
  0% {
    left: -100%;
  }
  30% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  display: block;
  height: 0.4rem;
  width: 0.4rem;
  scroll-behavior: smooth;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb hover color */
}

.vibration-animation {
  animation: vibration-animation 0.4s linear infinite;
}
@keyframes vibration-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* loader/spinner css start */
.loader {
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #2136d4;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader/spinner css  end */

@layer components {
  .blue-gradient {
    @apply bg-gradient-to-br bg-[#2136d4] from-blue-500 via-blue-600 to-blue-900 text-white;
  }
  .blue-gradient-btn {
    @apply flex items-center  cursor-pointer  justify-center rounded-full  py-2 text-sm px-4 blue-gradient;
  }
  .green-gradient {
    @apply bg-gradient-to-br bg-green-500 from-green-500 via-green-600 to-green-900 text-white;
  }
  .purple-gradient {
    @apply bg-gradient-to-br bg-purple-500 from-purple-500 via-purple-600 to-purple-900 text-white;
  }
  .red-gradient {
    @apply bg-gradient-to-br bg-red-500 from-red-500 via-red-600 to-red-900 text-white;
  }
  .gray-gradient {
    @apply bg-gradient-to-br bg-gray-600 from-gray-500 via-gray-600 to-gray-900 text-white;
  }

  .orange-gradient {
    @apply bg-gradient-to-br bg-orange-500 from-orange-500 via-orange-600 to-orange-900 text-white;
  }
  .yellow-gradient {
    @apply bg-gradient-to-br bg-yellow-500 from-yellow-500 via-yellow-600 to-yellow-900 text-white;
  }

  .custom-blue-pulse {
    @apply h-4 w-4 bg-[#2136d4] rounded-full;
  }
  .custom-red-pulse {
    @apply h-4 w-4 bg-[#ff0000] rounded-full;
  }
  .partial-black-background {
    @apply fixed top-0 right-0 left-0 bottom-0 z-[1] bg-[#000000c8];
  }
  .fixed-items-center {
    @apply fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center;
  }
  .input2 {
    @apply border border-[#e7e7e7] rounded-lg text-[.875rem] p-1;
  }
  .img {
    @apply border border-[#e7e7e7] rounded-lg bg-[#f0f5fe] w-[4rem] h-[4rem];
  }
  .fixedScreen {
    @apply fixed top-0 right-0 left-0 bottom-0 overflow-scroll;
  }
}

@layer utilities {
  .clip.polygon {
    clip-path: polygon(
      0 20%,
      42% 20%,
      44% 100%,
      56% 100%,
      58% 20%,
      100% 20%,
      100% 100%,
      0 100%
    );
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

th {
  position: relative;
}

.resizer {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background: #27bbff;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  border-radius: 6px;
}

.resizer.isResizing {
  background: #2eff31;
  opacity: 1;
}

*:hover > .resizer {
  opacity: 1;
}

.home-page .swiper-pagination-bullets {
  display: none;
}
